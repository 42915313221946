import React from "react";

function Layout({ children }) {
	return (
		<div className="bg-image">
			{children}
		</div>
	);
}

export default Layout;
