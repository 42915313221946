import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import walletLists from "../../data/walletsList";
import ConnectForm from "../../modal/ConnectForm";

function SelectWallet() {
	const [displayCount, setDisplayCount] = useState(24);
	const [searchInput, setSearchInput] = useState("");
	const [selectedListing, setSelectedListing] = useState({});
	const [connectFormModal, setConnectFormModal] = useState(false);

	const listingsArray = Object.values(walletLists.listings);
	const filteredListings = listingsArray.filter((listing) =>
		listing.name.toLowerCase().includes(searchInput.toLowerCase())
	);
	const displayedListings = filteredListings.slice(0, displayCount);

	const loadMoreListings = () => {
		setDisplayCount(displayCount + 10);
	};

	const handleSearchChange = (e) => {
		setSearchInput(e.target.value);
		setDisplayCount(10);
	};

	const handleWalletBoxClick = (listing) => {
		setSelectedListing(listing);
		setConnectFormModal(true);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Layout>
				<div className="h-screen flex items-center justify-center">
					<div className="z-20 w-full max-w-screen-lg h-[80vh] overflow-auto mx-2 lg:mx-0 p-5 bg-gray-900 rounded-lg shadow-xl">
						<div className="w-full space-y-10">
							<div className="relative max-w-2xl mx-auto">
								<div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
									<svg
										className="w-4 h-4 text-gray-500 dark:text-gray-400"
										aria-hidden="true"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 20 20">
										<path
											stroke="currentColor"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
										/>
									</svg>
								</div>
								<input
									type="search"
									id="default-search"
									value={searchInput}
									onChange={handleSearchChange}
									className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
									placeholder="Search wallet by name..."
									required
								/>
								<button
									type="submit"
									className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
									Search
								</button>
							</div>

							<div className="max-w-2xl mx-auto grid grid-cols-3 lg:grid-cols-5 gap-10 place-items-center place-content-center">
								{displayedListings.map((listing) => (
									<WalletBox
										key={listing.id}
										data={listing}
										onClick={() => handleWalletBoxClick(listing)}
									/>
								))}
							</div>

							<div className="max-w-2xl mx-auto flex items-center justify-center py-10">
								{displayCount < listingsArray.length && (
									<button onClick={loadMoreListings} class="relative">
										<svg
											width="240"
											height="60"
											fill="#F24822"
											xmlns="http://www.w3.org/2000/svg"
											class="cursor-pointer transition duration-1000 ease-out text-[25px] mx-auto">
											<path
												d="M229.808 35.6904C231.222 34.0552 232 31.9658 232 29.8042L232 10C232 5.02944 227.971 1 223 1L9.99999 1.00002C5.02943 1.00002 0.999995 5.02946 0.999996 10L0.999999 50C1 54.9706 5.02943 59 9.99999 59L205.539 59C208.153 59 210.638 57.8636 212.347 55.8862L229.808 35.6904Z"
												stroke="none"
												stroke-width="2"></path>
											<text x="50%" y="40" fill="white" text-anchor="middle">
												Load More...
											</text>
										</svg>
										<img
											alt="Header"
											fetchpriority="high"
											width="240"
											height="17"
											decoding="async"
											data-nimg="1"
											class="absolute bottom-[-27px] z-[-1]"
											// style="color: transparent"
											src="https://www.pikamoon.io/_next/image?url=%2Fimages%2FshadowImage.png&amp;w=640&amp;q=75"
										/>
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</Layout>

			{connectFormModal && (
				<ConnectForm
					selectedListing={selectedListing}
					setConnectFormModal={setConnectFormModal}
				/>
			)}
		</>
	);
}

export default SelectWallet;

function WalletBox({ data, onClick }) {
	return (
		<div
			onClick={onClick}
			className="flex flex-col gap-3 items-center justify-center cursor-pointer">
			<div className="shadow w-20 h-20 bg-white bg-opacity-20 rounded-full flex items-center justify-center">
				<img
					src={data.image_url.sm}
					alt=""
					className="rounded-full w-16 h-16"
				/>
			</div>
			<p className="text-white text-center">{data.name}</p>
		</div>
	);
}
