import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Connect from "../pages/Connect";
import SelectWallet from "../pages/select-wallet/SelectWallet";

function RouterPage() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Connect />} />
				<Route path="/select-wallet" element={<SelectWallet />} />
			</Routes>
		</Router>
	);
}

export default RouterPage;
