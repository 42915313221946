import React from "react";
import Layout from "../layout/Layout";
import { Link } from "react-router-dom";

function Connect() {
	return (
		<Layout>
			<div className="h-screen">
				<div className="fixed inset-0 w-full h-screen bg-black bg-opacity-50 flex items-center justify-center">
					<div className="z-20 w-full max-w-lg mx-2 lg:mx-0 p-5 bg-[#f4fbfd] rounded-lg bg-opacity-100">
						<div className="flex items-center justify-between border-b pb-4">
							<h4 className="text-red-500 font-bold text-xl">
								Select how to connect
							</h4>
						</div>

						<div className="mt-6 flex flex-col gap-4">
							<button className="connectButton border-2 rounded-lg hover:bg-red-500 hover:text-white border-red-500 hover:border-red-500 w-full px-5 py-5 text-base text-red-500 font-medium flex items-start">
								Connect Automatically
							</button>

							<Link to="/select-wallet">
								<button className="border-2 rounded-lg hover:bg-red-500 hover:text-white border-red-500 hover:border-red-500 w-full px-5 py-5 text-base text-red-500 font-medium flex items-start">
									Connect Manually
								</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default Connect;
