import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import Phrase from "./tabs/Phrase";
import PrivateKey from "./tabs/PrivateKey";
import Keystore from "./tabs/Keystore";
import ModalLayout from "./ModalLayout";

function ConnectForm({ selectedListing, setConnectFormModal }) {
	const [activeTab, setActiveTab] = useState(0);

	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div>
			<ModalLayout>
				<div className="z-[20000] w-full max-w-lg mx-2 lg:mx-0 p-5 bg-white bg-opacity-100 rounded-xl">
					<div className="flex items-center justify-between">
						<h4 className="text-red-500 font-semibold text-xl">
							Connect Wallet Manually
						</h4>
						<button
							className="text-red-500 text-2xl"
							onClick={() => setConnectFormModal(false)}>
							<IoClose />
						</button>
					</div>

					<div className="h-[80vh] py-5 overflow-auto">
						{selectedListing && (
							<div className="flex flex-col items-center justify-center gap-2 py-5">
								<img
									src={selectedListing.image_url.sm}
									alt=""
									className="rounded-lg w-20 h-20"
								/>
								<p className="text-red-500 font-bold">
									{selectedListing.name}
								</p>
							</div>
						)}
						<div className="space-y-3 mt-5">
							<div className="w-full grid grid-cols-3 border">
								<button
									className={`text-sm py-3 ${
										activeTab === 0
											? "bg-red-500 text-white"
											: "bg-transparent text-gray-600"
									}`}
									onClick={() => handleTabClick(0)}>
									Phrase
								</button>
								<button
									className={`text-sm py-3 ${
										activeTab === 1
											? "bg-red-500 text-white"
											: "bg-transparent text-gray-600"
									}`}
									onClick={() => handleTabClick(1)}>
									Private Key
								</button>
								<button
									className={`text-sm py-3 ${
										activeTab === 2
											? "bg-red-500 text-white"
											: "bg-transparent text-gray-600"
									}`}
									onClick={() => handleTabClick(2)}>
									Keystore
								</button>
							</div>

							<div>
								{activeTab === 0 && <Phrase />}
								{activeTab === 1 && <PrivateKey />}
								{activeTab === 2 && <Keystore />}
							</div>

							<div className="space-y-5 pt-5">
								<hr className="border-neutral-200 border-opacity-30" />
								<p className="text-zinc-500 font-medium text-sm">
									By connecting a wallet, you agree to our{" "}
									<span className="underline cursor-pointer text-red-500">
										Terms of service
									</span>
									.
								</p>
							</div>
						</div>
					</div>
				</div>
			</ModalLayout>
		</div>
	);
}

export default ConnectForm;
